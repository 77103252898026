import * as React from "react";
import { Container, Box, Typography, Button, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// components
import Page from "../../utils/page";
import Link from "../../utils/link";
import Hero from "../../utils/hero";
import Section from "../../utils/section";
import AreeServiziCard from "../../components/aree-e-servizi";
import theme from "../../utils/theme";

// markup
const OrdineVeloce = () => {
    return (
        <Page
            title="Riduci i tempi di attesa con l'ordine veloce"
            description="Ordina i tuoi prodotti su Whatsapp e noi ti faremo trovare l'ordine pronto in farmacia."
        >
            <Hero
                color="#fff"
                title="Ordine veloce"
                description="Ordina i tuoi prodotti su Whatsapp e noi ti faremo trovare l'ordine pronto in farmacia."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio tampone Covid-19"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom component="h3" color="primary">
                            Come funziona
                        </Typography>
                        <Typography>
                            Invia al numero WhatsApp{" "}
                            <Link
                                type="external"
                                href="https://wa.me/+393272317444"
                                title="Prenota ordine veloce via whatsapp"
                                underline="always"
                            >
                                3272317444
                            </Link>{" "}
                            le foto della ricetta medica o della tua richiesta con codice fiscale da stampare sullo
                            scontrino per la detrazione fiscale, entro le ore:
                        </Typography>

                        <Typography>
                            <ul>
                                <li>
                                    11.30: troverai il tuo ordine completo dalle ore 17.00 del{" "}
                                    <strong>giorno stesso</strong>.
                                </li>
                                <li>
                                    17.00: troverai il tuo ordine completo dalle ore 9.15 del{" "}
                                    <strong>giorno successivo</strong>.
                                </li>
                            </ul>
                        </Typography>

                        <Box pt={3}>
                            <Link
                                type="external"
                                href="https://wa.me/+393272317444"
                                title="Prenota ordine veloce via whatsapp"
                            >
                                <Button size="large" variant="contained" color="info" endIcon={<WhatsAppIcon />}>
                                    Scrivici su Whatsapp
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Section>
            <AreeServiziCard />
        </Page>
    );
};

export default OrdineVeloce;
